<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  v-if="categories!=null"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="categories" 
          
          class="select-size-lg"
 
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import useUsersList from '../courseCategoryStoreModule'
import axios from '@axios'
import { integer } from 'vee-validate/dist/rules'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    this.fetchCategories()
  
  },
  props :{
    initialCategories:[],
    initialId:integer
  },
 inject: ['categorydata'],

  methods:{
     updateSelections(a) {
    
    //console.log(a)
  },
         fetchCategories( ) {
      //console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/category' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          //console.log(responseData.data);
              this.categories=responseData.data;

          //console.log("inital Id received"+this.initialId);


          if ( this.selectedId!=null)
          {

        
              var   objIndex = this.initialCategories.findIndex((obj => obj.id == this.selectedId))
             this.initialCategories= this.objIndex;

          }

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    categorydata:   {
        handler:function (n, o)   {
          //console.log('new cat: ', n);
              if (n==null||n==undefined)
                  {
                    this.selectedId=null
                  } else
                  { 
                    //console.log('new client: ', n.selectedCategory);
                  this.selectedId = n.selectedCategory.upperCategory;
                  //console.log("selecedId"+ this.selectedId );
                   if ( this.selectedId!=null)
                     {

        
                           var   objIndex = this.categories.findIndex((obj => obj.id == this.selectedId))
                       //console.log("obj count="+this.categories.length);
                       this.selected= this.categories[objIndex];

                       }
                  }
                  
        

        },
        deep: true
    },
  selected: function (newVal,coldVal) {
     

     this.$emit("selectionChanged", newVal);

  }
},
  data() {
    return {
      categories:null,
      selectedId:null,
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
   

}
</script>