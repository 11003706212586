import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategories(ctx, queryParams) {
      //console.log("fetchCategories");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/category', { params: queryParams })
          .then((response)=> { 
           

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, userData) {
     //console.log(userData);
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/category',  userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
